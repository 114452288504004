import HTTPService from "@/services/http_service.js";

export default class UsuarioService extends HTTPService {
    resource = 'usuario'
    alterarSenha (responseFunctions, senhas, idUsuario) {
        this.request(responseFunctions, 'POST', this.resource+'/'+idUsuario+'/alterar-senha', senhas)
    }
    
    novaSenha (responseFunctions, senha) {
        this.request(responseFunctions, 'POST', this.resource+'/admchange-senha', senha)
    }

  esqueciSenha(responseFunctions, email) {
    this.request(
      responseFunctions,
      "POST",
      "auth/" + this.resource + "/esqueci-a-senha",
      email
    );
  }

  getUsuarioData({ onSucess, onError, onEnd }, idUsuario) {
    const url = `${this.resource}/${idUsuario}`;
    this.request({ onSucess, onError, onEnd }, "GET", url);
  }

  updateUsuarioData({ onSucess, onError, onEnd }, idUsuario, data) {
    const url = `${this.resource}/${idUsuario}`;
    this.request({ onSucess, onError, onEnd }, "POST", url, data);
  }

  updateUsuarioDataFile({ onSucess, onError, onEnd }, idUsuario, data) {
    const url = `${this.resource}/${idUsuario}`;
    this.fileRequest({ onSucess, onError, onEnd }, "POST", url, data);
  }

  getAceiteTermo(idCliente) {
    return this.get(`cliente/aceitetermo/${idCliente}`);
  }

  postAceiteTermo(responseFunctions, model) {
    this.jsonRequest(responseFunctions, "POST", `cliente/aceitetermo`, model);
  }
}
